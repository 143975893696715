document.addEventListener("turbo:load", function () {
  document.querySelectorAll(".chart_envios").forEach(function (element) {
    var figure = element.getAttribute("data-figure");
    // Assuming figure is a JSON string; parse it to an object if necessary
    Plotly.newPlot(element, JSON.parse(figure));
  });

  document.querySelectorAll(".tabs-with-plotly").forEach(function (tabElement) {
    // Assuming you're using a tabs library; you would need to initialize it here
    initializeTabs(tabElement, {
      onShow: function (tab) {
        var chart = tab.querySelector(".chart_envios");
        if (chart !== undefined) {
          var figure = chart.getAttribute("data-figure");
          // Again, assuming figure is a JSON string
          Plotly.newPlot(chart, JSON.parse(figure));
        }
      },
    });
  });
});

function initializeTabs(element, options) {
  // Placeholder for tab initializing logic (if a library is used, you'd invoke it here)
  // Example of manually adding show event listener
  var tabLinks = element.querySelectorAll('[role="tab"]');
  tabLinks.forEach(function (link) {
    link.addEventListener("click", function () {
      var tabId = link.getAttribute("href").substring(1);
      var tabContent = document.getElementById(tabId);
      options.onShow(tabContent);
    });
  });
}

document.addEventListener("turbo:load", function () {
  document.querySelectorAll(".alert_close").forEach(function (element) {
    element.addEventListener("click", function () {
      var alertBox = document.getElementById("alert_box");
      alertBox.style.transition = "opacity 0.5s ease-out"; // Adjust the timing as needed
      alertBox.style.opacity = "0";
      setTimeout(function () {
        alertBox.style.display = "none";
      }, 500); // Match the duration with the transition timing
    });
  });
});
